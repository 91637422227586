exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fizjociekawostki-js": () => import("./../../../src/pages/fizjociekawostki.js" /* webpackChunkName: "component---src-pages-fizjociekawostki-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-warsztaty-js": () => import("./../../../src/pages/warsztaty.js" /* webpackChunkName: "component---src-pages-warsztaty-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/PostPage.js" /* webpackChunkName: "component---src-templates-post-page-js" */)
}

